/**
 * This is used to create Svg icons.
 * Wrap an Svg <path> with the `createSvgIcon` hoc to create an icon.
 */

import React from 'react';
import './Icon.scss';

export type IconColorTokens = 'neutral-main';

export interface IconIconProps {
  size?: number | string;
  fill?: string;
  colorToken?: IconColorTokens;
  className?: string;
}

export type Icon = React.ComponentType<IconIconProps>;

interface CreateIconProps extends IconIconProps {
  title: string;
  children: React.ReactNode;
}

function Icon(props: CreateIconProps): React.ReactElement {
  const { children, title, size, colorToken, fill, className = '', ...rest } = props;
  const fillColor = colorToken ? `var(--${colorToken})` : fill;
  return (
    <svg width={size} height={size} fill={fillColor} focusable="false" viewBox="0 0 48 48" className={`TDB-Icon ${className}`} {...rest}>
      {title && <title>{title}</title>}
      {children}
    </svg>
  );
}

export function createSVGIcon(path: React.ReactElement, title: string): React.ComponentType<IconIconProps> {
  const SVG: React.FC<IconIconProps> = (props) => (
    <Icon title={title} {...props}>
      {path}
    </Icon>
  );
  return React.memo(SVG);
}
